<template>
  <div>
    <Top />
    <div class="success" @click="success()">
      <img src="../../public/img/成功.png" alt="" />
      <div class="zhifu" >支付成功！</div>
   
    </div>
  </div>
</template>

<script>
import Top from "@/components/top.vue";
export default {
  data() {
    return {
      number: 100,
    };
  },
  components: {
    Top,
  },
  methods:{
    success(){
      // this.$router.push("/paystatus")
    }
  }
};
</script>

<style scoped>
.success {
  width: 100%;
  height: calc(100vh-8vh);
  position: relative;
  margin: auto;
}
img {
  width: 0.6rem;
  height: 0.6rem;
  position: absolute;
  top: 20vh;
  left: 35.5vw;
}
.zhifu {
  width: 100%;
  font-size: 0.16rem;
  position: absolute;
  top: 32vh;
  left: 42vw;
  /* text-align: center; */
}
.num {
  width: 100%;
  height: 0.2rem;
  font-size: 0.16rem;
  color: gainsboro;
  line-height: 0.2rem;
  position: absolute;
  top: 36vh;
  /* text-align: center; */
  left: 38vw;
}
.go{
    width: 1rem;
    height: 0.2rem;
    border: 0.01rem solid rgb(200,227,227);
    font-size: 0.16rem;
    text-align: center;
    position: absolute;
    top: 50vh;
    color:rgb(200,227,227);
    left: 37vw;
    font-family: "宋体";
    border-radius: 0.15rem;
    line-height: 0.2rem;
}
</style>